import { Grid, Paper } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { color_exports } from "../../Styles/Color_exports";
import { userdetail_styles } from "../../Styles/Adminuser_details_styles";
import Badge from "../../assets/Icons/Badge";
import Verify from "../../assets/Icons/Verify";
import checkverify from "../../assets/Images/checkverify.png";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import uploadimg from "../../assets/Images/uploadimg.png";

const User_details = () => {
  const details_style = userdetail_styles();
  const location = useLocation();
  const { userData = {}, dashboardData = {} } = location.state || {};

  const { imageBaseUrl = "" } = userData;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const profileImages = userData.profileProfileImages?.slice(0, 4) || [
    { image1: uploadimg },
    { image2: uploadimg },
    { image3: uploadimg },
    { image4: uploadimg },
  ];
  const handleImageError = (event) => {
    event.target.style.display = 'none'; // Hide the broken image
  };
  return (
    <>
      <Grid container direction={"column"} mb={3} spacing={2}>
        <Grid item container justifyContent="space-between">
          <Grid item className={details_style.mainheading} mt={1} ml={1}>
            Uploaded Pics
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item container md={5} spacing={0.5}>
          {profileImages.length > 0 ? (
              profileImages.map((imageObj, index) => {
                const imageKey = `image${index + 1}`;
                const imageUrl = imageObj[imageKey]
                  ? `${imageBaseUrl}${imageObj[imageKey]}`
                  : uploadimg;
                return (
                  <Grid item md={3} key={index}>
                    <img
                      src={imageUrl}
                      alt={`Uploaded Image ${index + 1}`}
                      className={details_style.uploaded_images}
                      onError={handleImageError} // Handle broken image
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid item md={12} style={{ textAlign: "center", color: "#888" }}>
                <p>No images uploaded</p>
              </Grid>
            )}
          </Grid>

          <Grid item md={7}>
            <Paper elevation={5} className={details_style.upload_sty}>
              <Grid container direction={"column"} spacing={1}>
                <Grid item container>
                  <Grid item container md={5.5}>
                    <Grid
                      item
                      md
                      fontWeight={700}
                      sx={{ color: color_exports.tex_shade_color }}
                    >
                      ID: JM00100{userData.serialNo || "N/A"}
                    </Grid>
                    <Grid item container md={"auto"} gap={1}>
                      {userData.badgeStatus === 0 ? (
                        <img src={checkverify} />
                      ) : (
                        <Verify />
                      )}
                      <Badge />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mt={1} container justifyContent={"space-between"}>
                  <Grid item container md={5.5}>
                    <Grid item md className={details_style.details_heading}>
                      Profile
                    </Grid>
                    <Grid item container md={"auto"} gap={1} fontSize={14}>
                      {userData.profileProfilePercentage || "N/A"}% Completed
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={userData.profileProfilePercentage || 0}
                    />
                  </Grid>
                  <Grid item container md={5.5}>
                    <Grid item md className={details_style.details_heading}>
                      No. of Match Requests Received
                    </Grid>
                    <Grid item container md={"auto"} gap={1} fontSize={14}>
                      {dashboardData?.received || "N/A"}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container justifyContent={"space-between"}>
                  <Grid item container md={5.5}>
                    <Grid item md className={details_style.details_heading}>
                      No. of Profiles viewed
                    </Grid>
                    <Grid item container md={"auto"} gap={1} fontSize={14}>
                      {dashboardData?.profileViewCount || "N/A"}
                    </Grid>
                  </Grid>
                  <Grid item container md={5.5}>
                    <Grid item md className={details_style.details_heading}>
                      No.of Match Requests Accepted
                    </Grid>
                    <Grid item container md={"auto"} gap={1} fontSize={14}>
                      {dashboardData?.accepted || "N/A"}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container justifyContent={"space-between"}>
                  <Grid item container md={5.5}>
                    <Grid item md className={details_style.details_heading}>
                      No.of Match Request Sent
                    </Grid>
                    <Grid item container md={"auto"} gap={1} fontSize={14}>
                      {dashboardData?.sent || "N/A"}
                    </Grid>
                  </Grid>
                  <Grid item container md={5.5}>
                    <Grid item md className={details_style.details_heading}>
                      No.of Matches Contacted
                    </Grid>
                    <Grid item container md={"auto"} gap={1} fontSize={14}>
                      {dashboardData?.contacted || "N/A"}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid item container md={12} spacing={2}>
          {/* Personal Information */}
          <Grid item md={4}>
            <Paper elevation={5} className={details_style.upload_sty}>
              <Grid container direction={"column"} spacing={1}>
                <Grid item className={details_style.mainheading}>
                  Personal Information
                </Grid>
                <Grid item mt={1} container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Name
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {`${userData.firstName} ${userData.lastName}`}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Age
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {new Date().getFullYear() -
                      new Date(userData.profileDateOfBirth).getFullYear()}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    D.O.B
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {new Date(userData.profileDateOfBirth).toLocaleDateString()}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Gender
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {`${userData.profileGender}`}
                  </Grid>
                </Grid>
                 
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Height
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileHeight}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Mother Tongue
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {`${userData.profileMotherTongue}`}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Physical Status
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {`${userData.profilePhysicalStatus}`}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Languages
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileLanguages}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Hobbies
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileHobbies}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Habits
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileHabits}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Zodaic Sign
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileZodaicSign}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Marital Status
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileMaritalStatus}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* Family Information */}
          <Grid item md={4}>
            <Paper elevation={5} className={details_style.upload_sty}>
              <Grid container direction={"column"} spacing={1}>
                <Grid item className={details_style.mainheading}>
                  Family Information
                </Grid>
                <Grid item mt={1} className={details_style.sub_heading}>
                  Father details
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Name
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileFatherName}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Occupation
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileFatherProfession}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Parent/Guardian Phone Number
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileGaurdianNumber}
                  </Grid>
                </Grid>
                <Grid item className={details_style.sub_heading}>
                  Mother details
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Name
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileMotherName}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Occupation
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileMotherProfession}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Family Value
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileFamilyValue}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Family Type
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileFamilyType}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Family Status
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileFamilyStatus}
                  </Grid>
                </Grid>

                <Grid item className={details_style.sub_heading}>
                  Children details
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    No.of Childrens
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileNoChildren >= 0
                      ? userData.profileNoChildren
                      : "N/A"}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Children's Living Status
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileNoChildrenLivingStatus >= 0
                      ? userData.profileNoChildrenLivingStatus
                      : "N/A"}
                  </Grid>
                </Grid>
                <Grid item className={details_style.sub_heading}>
                  Siblings details
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    No. of Brothers
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileNoBrothers >= 0
                      ? userData.profileNoBrothers
                      : "N/A"}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    No. of Brothers Married
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileNoBrothersMarried >= 0
                      ? userData.profileNoBrothersMarried
                      : "N/A"}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    No. of Sisters
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileNoSisters >= 0
                      ? userData.profileNoSisters
                      : "N/A"}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    No. of Sisters Married
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileNoSistersMarried >= 0
                      ? userData.profileNoSistersMarried
                      : "N/A"}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={4}>
            <Grid
              container
              direction="column"
              spacing={2}
              style={{ height: "100%" }}
            >
              {/* Address */}
              <Grid item className={details_style.card1}>
                <Paper elevation={5} className={details_style.upload_sty1}>
                  <Grid
                    container
                    direction="column"
                    spacing={1}
                    style={{ flex: 1 }}
                  >
                    <Grid item className={details_style.mainheading}>
                      Address
                    </Grid>
                    <Grid item mt={1} container>
                      <Grid
                        item
                        md={5}
                        className={details_style.details_heading}
                      >
                        Present
                      </Grid>
                      <Grid item md={7} className={details_style.details_text}>
                        {userData.presentStreet &&
                          userData.presentStreet + ", "}
                        {userData.presentCity && userData.presentCity + ", "}
                        {userData.presentState && userData.presentState + ", "}
                        {userData.presentCountry &&
                          userData.presentCountry + ", "}
                        {userData.profilePresentPincode}
                      </Grid>
                    </Grid>

                    <Grid item container>
                      <Grid
                        item
                        md={5}
                        className={details_style.details_heading}
                      >
                        Permanent
                      </Grid>
                      <Grid item md={7} className={details_style.details_text}>
                        {/* {userData.profilePermanentStreet && userData.profilePermanentStreet + ','}{" "} */}
                        {userData.permanentCity && userData.permanentCity + ","}{" "}
                        {userData.permanentState && userData.permanentState + ","}{" "}
                        {userData.permanentCountry  && userData.permanentCountry + ","}{" "}
                        {userData.profilePermanentPincode && userData.profilePermanentPincode}{" "}
                       
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {/* Education details */}
              <Grid item className={details_style.card1}>
                <Paper elevation={5} className={details_style.upload_sty2}>
                  <Grid
                    container
                    direction="column"
                    spacing={1}
                    style={{ flex: 1 }}
                  >
                    <Grid item className={details_style.mainheading}>
                      Education details
                    </Grid>
                    <Grid item container>
                      <Grid
                        item
                        md={5}
                        className={details_style.details_heading}
                      >
                        Education
                      </Grid>
                      <Grid item md={7} className={details_style.details_text}>
                        {userData.profileQualification}
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid
                        item
                        md={5}
                        className={details_style.details_heading}
                      >
                        School/ College/ University
                      </Grid>
                      <Grid item md={7} className={details_style.details_text}>
                        {userData.profileUniversityName}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={12} spacing={2}>
          {/* Contact Information */}
          <Grid item md={4}>
            <Paper elevation={5} className={details_style.upload_sty}>
              <Grid container direction={"column"} spacing={1}>
                <Grid item className={details_style.mainheading}>
                  Contact Information
                </Grid>
                <Grid item mt={2} container>
                  <Grid item md={4.5} className={details_style.details_heading}>
                    Mobile number
                  </Grid>
                  <Grid item md={7.5} className={details_style.details_text}>
                    {userData.phoneNumber}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={4.5} className={details_style.details_heading}>
                    Email
                  </Grid>
                  <Grid item md={7.5} className={details_style.details_text1}>
                    {userData.email}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* Religious Information */}
          <Grid item md={4}>
            <Paper elevation={5} className={details_style.upload_sty}>
              <Grid container direction={"column"} spacing={1}>
                <Grid item className={details_style.mainheading}>
                  Religious Information
                </Grid>
                <Grid item container>
                  <Grid item md={4.5} className={details_style.details_heading}>
                    Religion
                  </Grid>
                  <Grid item md className={details_style.details_text}>
                    {userData.profileReligion}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={4.5} className={details_style.details_heading}>
                    Caste
                  </Grid>
                  <Grid item md className={details_style.details_text}>
                    {userData.profileCaste}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={4.5} className={details_style.details_heading}>
                    Sub-caste
                  </Grid>
                  <Grid item md className={details_style.details_text}>
                    {userData.profileSubCaste || "N/A"}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* Professional details */}
          <Grid item md={4}>
            <Paper elevation={5} className={details_style.upload_sty}>
              <Grid container direction={"column"} spacing={1} mb={"25%"}>
                <Grid item className={details_style.mainheading}>
                  Professional details
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Employed In
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileEmployedIn}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Occupation
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileOccupation}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Annual Income
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileAnnualIncome}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Job Location
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileJobLocation}
                  </Grid>
                </Grid>
                {/* <Grid item container>
                  <Grid item md={5} className={details_style.details_heading}>
                    Aadhar Number
                  </Grid>
                  <Grid item md={7} className={details_style.details_text}>
                    {userData.profileAadharNumber}
                  </Grid>
                </Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {/* About Me */}
        <Grid item>
          <Paper elevation={5} className={details_style.upload_sty}>
            <Grid container direction={"column"} spacing={1}>
              <Grid item className={details_style.mainheading}>
                About Me
              </Grid>
              <Grid item className={details_style.details_text}>
                {userData.profileAboutMe}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default User_details;
