import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { color_exports } from "../Styles/Color_exports";
import Modifiedbutton from "../Components/Modifiedbutton";
import Modifiedsearchbar from "../Components/Modifiedsearchbar";
import Viewstorycomp from "../Components/Viewstory";
import Storycomp from "../Components/Story";
import Poststorycomp from "../Components/Poststory";
import { enqueueSnackbar } from "notistack";
import { editorstyles } from "../Styles/Contentmanagement_styles";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const StoryContent = ({
  stories,
  selectedStory,
  fetchStoryDetails,
  showPostNew,
  handleClosePostNew,
  handleNewStoryPosted,
  imageBaseUrl
}) => (
  <Grid container spacing={2}>
    {stories.length === 0 ? (
      <>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            No stories found
          </Typography>
        </Grid>
        {showPostNew && (
          <Grid item xs={6}>
            <Poststorycomp color_exports={color_exports} close={handleClosePostNew} onNewStoryPosted={handleNewStoryPosted} />
          </Grid>
        )}
      </>
    ) : (
      <>
        <Grid item md={6}>
          <Storycomp color_exports={color_exports} stories={stories} onStoryClick={fetchStoryDetails} />
        </Grid>
        <Grid item md={6} mb={3}>
          {showPostNew ? (
            <Poststorycomp color_exports={color_exports} close={handleClosePostNew} onNewStoryPosted={handleNewStoryPosted} />
          ) : (
            selectedStory && Object.keys(selectedStory).length > 0 && (
              <Viewstorycomp color_exports={color_exports} story={selectedStory} imageBaseUrl={imageBaseUrl} />
            )
          )}
        </Grid>
      </>
    )}
  </Grid>
);

const SuccessStories = () => {
  const [value, setValue] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [showPostNew, setShowPostNew] = useState(false);
  const [allStories, setAllStories] = useState([]);
  const [recentStories, setRecentStories] = useState([]);
  const [postedStories, setPostedStories] = useState([]);
  const [selectedStory, setSelectedStory] = useState(null);
  const [imageBaseUrl, setImageBaseUrl] = useState(""); 
  const latestSearchText = useRef(searchText);
  const editorstyle = editorstyles();
  const navigate = useNavigate(); // Initialize useNavigate

  const filterTypeMap = {
    all: 1,
    recent: 2,
    posted: 3
  };

  const fetchStories = async (filterType, searchText = '') => {
    try {
      const mappedFilterType = filterTypeMap[filterType] || 1;
      const req = { min: 0, max: 50, filterType: mappedFilterType };
  
      if (searchText.trim() !== '') {
        req.searchText = searchText;
      }
  
      const defaultAuthKey = process.env.REACT_APP_DEFAULT_AUTHKEY;
      const backendUrl = process.env.REACT_APP_BACKEND_URL;  // Use env variable for backend URL
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${defaultAuthKey}`,
      };
  
      const response = await axios.post(
        `${backendUrl}/getSuccessStories`,  // Dynamic backend URL
        req,
        { headers }
      );
  
      if (response.data.imageBaseUrl) {
        setImageBaseUrl(response.data.imageBaseUrl);
      }
  
      const stories = Array.isArray(response.data.data.rows) ? response.data.data.rows : [];
      
      switch (mappedFilterType) {
        case 1:
          setAllStories(stories);
          break;
        case 2:
          setRecentStories(stories);
          break;
        case 3:
          setPostedStories(stories);
          break;
        default:
          break;
      }
    } catch (err) {
      console.error('Error during fetching stories:', err);
      enqueueSnackbar('Failed to fetch stories', { variant: 'error' });
    }
  };
  

  const fetchStoryDetails = async (successStoryId) => {
    try {
      const req = { successStoryId };
  
      const defaultAuthKey = process.env.REACT_APP_DEFAULT_AUTHKEY; // Use environment variable for auth key
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${defaultAuthKey}`,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/getSuccessStoryById`, // Use environment variable for base URL
        req,
        { headers }
      );
  
      if (response.data.imageBaseUrl) {
        setImageBaseUrl(response.data.imageBaseUrl);
      }
  
      if (response.data.data) {
        setSelectedStory(response.data.data);
        setShowPostNew(false);
      }
    } catch (err) {
      console.error('Error during fetching story details:', err);
      enqueueSnackbar('Failed to fetch story details', { variant: 'error' });
    }
  };
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/admin/success?tab=${newValue}`); // Update URL with tab parameter
    fetchStories(newValue);
  };

  const handlePostNewClick = () => {
    setShowPostNew(prev => !prev);
    setSelectedStory(null);
  };

  const handleClosePostNew = () => {
    setShowPostNew(false);
  };

  const handleSearchClick = async () => {
    latestSearchText.current = searchText;
    try {
      await fetchStories(value, searchText);
      setSearchText("");
    } catch (err) {
      console.error("Error during search:", err);
      enqueueSnackbar("Failed to search stories", { variant: "error" });
    }
  };

  const handleNewStoryPosted = (newStory) => {
    setPostedStories(prev => [newStory, ...prev]);
    setShowPostNew(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tabParam = params.get('tab') || 'all';
    setValue(tabParam);
    fetchStories(tabParam);
  }, []);

  return (
    <Grid container maxWidth="xl" sx={{ marginTop: "2rem" }}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Grid container spacing={2}>
            <Grid item>
              <TabList
                scrollButtons="on"
                variant="scrollable"
                orientation="horizontal"
                onChange={handleChange}
                className={editorstyle.tablisted}
                aria-label="lab API tabs example"
              >
                <Tab
                  sx={{ textTransform: "none", padding: "0px" }}
                  label="All"
                  value="all"
                  disableRipple
                />
                <Tab
                  sx={{ textTransform: "none", padding: "0px" }}
                  label="Recent"
                  value="recent"
                  disableRipple
                />
                <Tab
                  sx={{ textTransform: "none", padding: "0px" }}
                  label="Posted"
                  value="posted"
                  disableRipple
                />
              </TabList>
            </Grid>

            <Grid item>
              <Box>
                <Grid container alignItems="center">
                  <Grid item md="auto">
                    <Modifiedsearchbar
                      placeholder="Search with Name & Height"
                      width="20rem"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Grid>
                  <Grid item md ml={1}>
                    <Modifiedbutton
                      swidth="8rem"
                      sheight="2.5rem"
                      data="Search"
                      onClick={handleSearchClick}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
              
            <Grid item>
              <Modifiedbutton
                data={showPostNew ? "Hide New Story" : "Post New Story"}
                onClick={handlePostNewClick}
              />
            </Grid>
          </Grid>
          <TabPanel value="all">
            <StoryContent
              stories={allStories}
              selectedStory={selectedStory}
              fetchStoryDetails={fetchStoryDetails}
              showPostNew={showPostNew}
              handleClosePostNew={handleClosePostNew}
              handleNewStoryPosted={handleNewStoryPosted}
              imageBaseUrl={imageBaseUrl}
            />
          </TabPanel>
          <TabPanel value="recent">
            <StoryContent
              stories={recentStories}
              selectedStory={selectedStory}
              fetchStoryDetails={fetchStoryDetails}
              showPostNew={showPostNew}
              handleClosePostNew={handleClosePostNew}
              handleNewStoryPosted={handleNewStoryPosted}
              imageBaseUrl={imageBaseUrl}
            />
          </TabPanel>
          <TabPanel value="posted">
            <StoryContent
              stories={postedStories}
              selectedStory={selectedStory}
              fetchStoryDetails={fetchStoryDetails}
              showPostNew={showPostNew}
              handleClosePostNew={handleClosePostNew}
              handleNewStoryPosted={handleNewStoryPosted}
              imageBaseUrl={imageBaseUrl}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Grid>
  );
};

export default SuccessStories;
