import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login_status: 0,
  skip_status: 0,
  data: {
    privileges: {},
    preferences: { successStories: 0 }, // Use the correct key based on the API response
  },
  user_type: 0,
  email: "",
};

const Loginslice = createSlice({
  name: "login",
  initialState,
  reducers: {
    admin_login(state, action) {
      console.log("admin_login", action.payload);
      state.data = action.payload.adminres || state.data;
      state.email = action.payload.email;
    },
    Subadmin_login(state, action) {
      state.data = action.payload.adminres || state.data;
      state.email = action.payload.email;
    },
    updatePreferences(state, action) {
      state.data.preferences = {
        ...state.data.preferences,
        ...action.payload,
      };
    },
    logout(state) {
      state.login_status = 0;
      state.skip_status = 0;
      state.data = {
        privileges: {},
        preferences: { successStories: 0 }, // Reset preferences with the correct key
      };
      state.user_type = 0;
      state.email = "";
    },
  },
});

export const LoginActions = Loginslice.actions;
export default Loginslice.reducer;
