import React, { useEffect, useState } from "react";
import { chatmessagestyles } from "../../Styles/Chatstyles";
import { Avatar, Checkbox, Chip, Divider, Grid } from "@mui/material";
import Verify from "../../assets/Icons/Verify";
import {
  onSnapshot,
  orderBy,
  query,
  where,
  collection,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../Firebase/Firebaseconfig";
import TimeAgo from "javascript-time-ago";

// English.
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
const timeAgo = new TimeAgo("en-US");

const Chats = ({
  selectChat,
  receiverquery,
  handleselected_chatrooms,
  selected_chatrooms,
  selectchatrooms,
}) => {
  const chatstyle = chatmessagestyles();
  const [users, setUsers] = useState([]);
  const [usersDetails, setUsersDetails] = useState([]);
  const [receiver_search, setReceiver_search] = useState("");
  const [admin_firebasedata, setAdmin_firebasedata] = useState({});
  const [blockedUsers, setBlockedUsers] = useState([]);
  const formatedtime = timeAgo.format(new Date());

  const getuser_details = async (documentId) => {
    try {
      const docRef = doc(db, "users_info", documentId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching document: ", error);
      return null;
    }
  };

  async function userchatinfo(propsdata = {}) {
    const { searchuser = receiver_search } = propsdata;
    try {
      const getmsgs_query = query(
        collection(db, "chat_room"),
        where("nonDeletedUsers", "array-contains", "admin"),
        orderBy("recentMessageTime", "desc")
      );

      onSnapshot(getmsgs_query, async (snapshot) => {
        let rooms = [];
        let blocked = [];
        snapshot.forEach(async (doc) => {
          let data = await getuser_details(
            doc.data().memberIds.filter((item) => item !== "admin")[0]
          );
          const isBlocked = doc.data().blockedUsers?.includes("admin");
          if (isBlocked) blocked.push(doc.id);
          rooms.push({
            ...doc.data(),
            id: doc.id,
            user_details: { ...data },
            blocked: isBlocked,
          });
          setUsersDetails((prev) => [...prev, data]);
        });
        if (searchuser) {
          rooms = rooms.filter((item) =>
            item?.user_details?.name
              .toLowerCase()
              .includes(searchuser.toLowerCase())
          );
        }
        rooms.sort((a, b) => a.updatedAt - b.updatedAt);
        setUsers(rooms);
        setBlockedUsers(blocked);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  const get_admin_info = async () => {
    try {
      const userDocRef = doc(db, "users_info", "admin");
      onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          setAdmin_firebasedata({ ...doc.data() });
        } else {
          console.log("No such document!");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (receiverquery !== receiver_search) {
      setReceiver_search(receiverquery);
      userchatinfo({ searchuser: receiverquery });
    }
  }, [receiverquery]);

  useEffect(() => {
    userchatinfo();
    get_admin_info();
  }, []);

  /**
   * Filtering the users based on the search name
   */
  const filteredUsers = users.filter((item) => {
    const name = item?.user_details?.name ?? "";
    return (
      typeof name === "string" &&
      name.toLowerCase().includes(receiver_search.toLowerCase())
    );
  });

  return (
    <>
      <Grid style={{ marginTop: "15px" }} className={chatstyle.Chats}>
        {filteredUsers.map((values, index) => {
          const name = values?.user_details?.name ?? "";
          const content_type = values?.recentMessage?.contentType ?? "";
          const message =
          content_type === "text"
            ? values?.blocked
              ? "You blocked this user"
              : values?.recentMessage?.text
            : content_type === "image"
            ? values?.blocked
              ? "You blocked this user"
              : `Image`
            : content_type === "video"
            ? values?.blocked
              ? "You blocked this user"
              : `Video`
            : "No messages";
        
          const receiver_img = values?.user_details?.photo ?? "";
          const badge_count = Object.keys(admin_firebasedata).length
            ? admin_firebasedata?.badge[values?.roomId] ?? 0
            : 0;

          return (
            <>
              <Grid
                container
                className={chatstyle.userchat}
                key={values.roomId}
                onClick={() => selectChat(values)}
              >
                {selectchatrooms && (
                  <Grid item md={1}>
                    <Checkbox
                      sx={{
                        "&.Mui-checked": { color: "#04BEF8" },
                        padding: "0px",
                      }}
                      disableRipple
                      checked={selected_chatrooms.has(values?.roomId)}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleselected_chatrooms(values);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={1}>
                  {receiver_img ? (
                    <Avatar src={receiver_img ?? ""} alt="receiverimage" />
                  ) : (
                    <Avatar />
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs //={9.5}
                  style={{ padding: "10px 15px 10px 10px " }}
                  className={chatstyle.userchatinfo}
                >
                  <Grid item container>
                    <Grid
                      item
                      xs={"auto"}
                      className={chatstyle.username}
                      sx={{
                        fontWeight: badge_count ? "700" : "400",
                        color: badge_count ? "#000000" : "#595959",
                      }}
                    >
                      {name}
                      {/* <span >{name}</span>{" "} */}
                    </Grid>
                    <Grid item xs={1}>
                      <span style={{ marginLeft: "5px" }}>
                        {" "}
                        {/* <Verify /> */}
                      </span>
                    </Grid>
                    {/* time
                    <Grid item container justifyContent={"flex-end"} xs>
                    {formatedtime}
                    </Grid> */}
                  </Grid>

                  <Grid item container>
                    <Grid item xs={11}>
                      <p
                        style={{ margin: "0px" }}
                        className={chatstyle.usermessage2}
                      >
                        {message}
                      </p>
                    </Grid>
                    <Grid item xs={1}>
                      {badge_count > 0 && (
                        <Grid
                          item
                          xs
                          container
                          justifyContent={"flex-end"}
                          direction="column"
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            justifyContent={"center"}
                          >
                            <Chip
                              label={badge_count}
                              size="small"
                              className={chatstyle.chipStyles}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={chatstyle.dividerstyle} />
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default Chats;
