import { makeStyles } from "@mui/styles";
import { color_exports } from "./Color_exports";

export const userdetail_styles = makeStyles({
  upload_sty: {
    padding: "1.5rem",
    borderRadius: "1rem",
    height: "calc(100% - 3rem)",
    marginRight: "1rem",
  },
  upload_sty1: {
    padding: "1.5rem",
    borderRadius: "1rem",
    height: "calc(100% - 3rem)",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginRight: "1rem",
  },
  upload_sty2: {
    padding: "1.5rem",
    borderRadius: "1rem",
    height: "calc(100% - 3rem)",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    marginRight: "1rem"
  },
  card1: {
    flex: 1,
    display: "flex",
  },
  uploaded_images: {
    borderRadius: "10px",
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  prflimg: {
    marginRight: "8px",
    width: "50px",
    height: "50px",
    borderRadius: "10px",
    objectFit: "cover",
  },
  mainheading: {
    fontSize: "14px",
    color: color_exports.primary_color,
    fontWeight: 700,
  },
  details_heading: {
    fontSize: "14px",
    color: color_exports.user_details_color,
    fontWeight:600,
  },
  details_text: {
    fontSize: "14px",
    color: color_exports.tex_shade_color,
    wordWrap: "break-word",
  },
  details_text1: {
    fontSize: "13px",
    color: color_exports.tex_shade_color,
    wordWrap: "break-word",
  },
  sub_heading: {
    fontSize: "14px",
    color: color_exports.primary_color,
    fontWeight: 400,
  },
  tabstyles: {
    "& .MuiTab-root": {
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: "17px",
      lineHeight: "17px",
      color: "#333333",
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: "#b8003e !important",
      fontWeight: 700,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#b8003e",
    },
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  columnItem: {
    flex: "1",
  },

  accimg: {
    width: "50px",
    height: "50px",
    borderRadius: "10px",
    marginRight: "10px",
    objectFit: "cover",
  },
  profileimgreport: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginLeft: "-1.1rem",
  },
  cellsty: {
    backgroundImage: 'linear-gradient(to top, rgb(255, 255, 255), rgb(249, 205, 220))', 
    borderBottom: '3px solid rgb(255, 255, 255) !important',
  }
});
