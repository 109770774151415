import React, { useState, useEffect } from "react";
import { Grid, ListItem, ListItemText, List } from "@mui/material";
import Modifiedbutton from "../Components/Modifiedbutton";
import { packageManagementStyles } from "../Styles/PackagemanagementStyles";
import Creategroup from "../Models/Creategroup";
import Createoreditpackage from "../Models/Createoreditpackage";
import Editpackage from "../Models/Editpackage";
import {
  getpackages_api,
  getgroupnames_api,
  getitemnames_api,
  updateitemdata_api,
} from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import Circlecheckbox from "../Components/Circlecheckbox";

const PackageManagement = () => {
  const classes = packageManagementStyles();
  const [creategroup, setCreategroup] = useState(false);
  const [createoreditpackage, setCreateoreditpackage] = useState(false);
  const [editpackage, setEditpackage] = useState(false);
  const [groupNames, setGroupNames] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [packages, setPackages] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updatedOptionsData, setUpdatedOptionsData] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await getpackages_api({ min: 0, max: 50 });
        if (
          response?.message === "All packages details retrieved successFully"
        ) {
          const packagesData = response.data.rows.map((pkg) => ({
            ...pkg,
            duration: Math.round(pkg.duration / 30.44), // Convert days to months and round to nearest integer
          }));
          setPackages(packagesData);
        } else {
          enqueueSnackbar(response?.message || "Failed to fetch packages", {
            variant: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching packages:", error);
        enqueueSnackbar("Failed to fetch packages", {
          variant: "error",
        });
      }
    };

    fetchPackages();
  }, []);

  useEffect(() => {
    const fetchGroupNames = async () => {
      try {
        const response = await getgroupnames_api();
        if (response?.message === "Group Data retrieved successfully") {
          setGroupNames(response.data);
        } else {
          enqueueSnackbar(response?.message || "Failed to fetch group names", {
            variant: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching group names:", error);
        enqueueSnackbar("Failed to fetch group names", {
          variant: "error",
        });
      }
    };

    fetchGroupNames();
  }, []);

  useEffect(() => {
    const fetchItemNames = async () => {
      try {
        const itemNamesMap = {};
        for (const group of groupNames) {
          const response = await getitemnames_api({
            packageGroupId: group.packageGroupId,
          });
          if (response?.message === "Group Items data Retirived successfully") {
            itemNamesMap[group.groupName] = response.data;
          } else {
            enqueueSnackbar(response?.message || "Failed to fetch item names", {
              variant: "error",
            });
          }
        }
        setItemNames(itemNamesMap);
      } catch (error) {
        console.error("Error fetching item names:", error);
        enqueueSnackbar("Failed to fetch item names", {
          variant: "error",
        });
      }
    };

    if (groupNames.length > 0) {
      fetchItemNames();
    }
  }, [groupNames]);

  const handleEditPackageOptions = async (
    updatedOptionsData,
    packageGroupItemId
  ) => {
    try {
      const response = await updateitemdata_api({
        packageGroupItemId: packageGroupItemId,
        itemName: "",
        packageData: updatedOptionsData,
      });
      if (response?.message === "Package options updated successfully") {
        enqueueSnackbar("Package options updated successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(
          response?.message || "Failed to update package options",
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      console.error("Error updating package options:", error);
      enqueueSnackbar("Failed to update package options", {
        variant: "error",
      });
    }
  };

  const handleEditPackageClick = (pkg) => {
    setSelectedPackage(pkg);
    setEditpackage(true);
  };

  const handleSaveEditOptions = async () => {
    try {
      await handleEditPackageOptions(
        updatedOptionsData,
        selectedPackage.PackageGroupItemId
      );
      setEditMode(false);
    } catch (error) {
      console.error("Error saving edit options:", error);
    }
  };
  const handleCheckboxChange = (itemName, packageId) => (e) => {
    const { checked } = e.target;
    setUpdatedOptionsData((prevState) => ({
      ...prevState,
      [itemName]: prevState[itemName].map((option) =>
        option.packageId === packageId ? { ...option, status: checked } : option
      ),
    }));
  };

  return (
    <div>
      <Creategroup
        open={creategroup}
        close={() => setCreategroup(false)}
        packageTitles={packages.map((pkg) => pkg.packageTitle)}
      />
      <Createoreditpackage
        open={createoreditpackage}
        close={() => setCreateoreditpackage(false)}
      />
      <Editpackage
        open={editpackage}
        close={() => setEditpackage(false)}
        packageData={selectedPackage}
        handleSaveEditOptions={handleSaveEditOptions}
        setUpdatedOptionsData={setUpdatedOptionsData}
      />
      <Grid container direction={"column"}>
        <Grid item container justifyContent={"space-between"}>
          <Grid item container md={"auto"} gap={2}></Grid>
          {/* <Grid item container md justifyContent={"end"}>
            <Modifiedbutton
              data={"+Create Package"}
              sheight={"2.5rem"}
              swidth={"10rem"}
              onClick={() => {
                setCreateoreditpackage(true);
              }}
            />
            <Modifiedbutton
              data={"+Create Group and Add Options"}
              sheight={"2.5rem"}
              swidth={"17rem"}
              styles={{ marginLeft: "1rem" }}
              onClick={() => {
                setCreategroup(true);
              }}
            />
          </Grid> */}
        </Grid>

        <Grid
          item
          container
          spacing={2}
          mt={2}
          className={classes.gridContainer}
        >
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.packageList}>
              <Grid item xs={3}>
                Packages
              </Grid>
              {packages.map((pkg, index) => (
                <Grid key={index} className={classes.packageListItem}>
                  <h3 style={{ marginRight: "10px" }} xs={3}>
                    {pkg.packageTitle}
                  </h3>
                </Grid>
              ))}
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          mt={2}
          className={classes.gridContainer1}
        >
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.packageList}>
              <Grid item xs={3}>
                Price
              </Grid>
              {packages.map((pkg, index) => (
                <Grid key={index} className={classes.packageListItem}>
                  <h3 style={{ marginRight: "10px", fontSize: "14px", fontWeight: "400", color: "#333333" }} xs={3}>
                    {pkg.price}
                  </h3>
                </Grid>
              ))}
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          mt={2}
          className={classes.gridContainer1}
        >
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.packageList}>
              <Grid item xs={3}>
                Duration
              </Grid>
              {packages.map((pkg, index) => (
                <Grid key={index} className={classes.packageListItem}>
                  <h3 style={{ marginRight: "10px", fontSize: "14px", fontWeight: "400", color: "#333333" }} xs={3}>
                    {pkg.duration} Months
                  </h3>
                </Grid>
              ))}
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          mt={2}
          className={classes.gridContainer1}
        >
          <Grid item xs={12} className={classes.gridItem}>
            <div className={classes.packageList}>
              <Grid item xs={3}>
                Description
              </Grid>
              {packages.map((pkg, index) => (
                <Grid key={index} className={classes.packageListItem}>
                  <h3 style={{ marginRight: "10px", fontSize: "14px", fontWeight: "400", color: "#333333" }} xs={3}>
                    {pkg.description}
                  </h3>
                </Grid>
              ))}
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          mt={2}
          className={classes.gridContainer2}
        >
          <Grid container direction={"column"}>
            <Grid item container direction={"column"}>
              {groupNames.map((group, groupIndex) => (
                <Grid item container spacing={2} mt={2} key={groupIndex}>
                  <Grid item xs={12}>
                    <Grid container className={classes.groupnamesty}>
                      <Grid item style={{marginTop: "-16px"}}>{group.groupName}</Grid>
                    </Grid>

                    <List>
                      {itemNames[group.groupName]?.map((item, index) => (
                        <Grid
                          container
                          className={classes.gridItem}
                          key={index}
                        >
                          <Grid item xs={3}>
                            <ListItem className={classes.itemnamesty}>
                              <ListItemText primary={item.itemName} style={{marginTop: "-5px"}}/>
                            </ListItem>
                          </Grid>
                          <Grid item container xs={9}>
                            {packages.map((pkg) => {
                              // Find the specific option for the current packageId
                              const option = item.optionsData.find(
                                (option) => option.packageId === pkg.packageId
                              );

                              return (
                                <Grid item key={pkg.packageId} container xs={2.4}>
                                  <Grid item xs={3}>
                                    <Circlecheckbox
                                      checked={option?.status === true}
                                      // onChange={handleCheckboxChange(item.itemName, pkg.packageId)}
                                    />
                                  </Grid>

                            
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PackageManagement;
