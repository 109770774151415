import React, { useState } from "react";
import {
  Grid,
  Paper,
  Stack,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import Modifiedinputfield from "../Components/Modifiedinputfield";
import Modifieddate from "../Components/Modifieddate";
import Modifiedtextarea from "../Components/Modifiedtextarea";
import Modifiedbutton from "../Components/Modifiedbutton";
import Modifiedbrowserfiles from "../Components/Modifiedbrowserfiles";
import { postsuccessstory_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import { postsuccessstorystyles } from "../Styles/Component_styles";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { adminloginstyles } from "../Styles/Admin_login_styles";

const PostStoryComp = ({ color_exports, close }) => {
  const adminstyles = adminloginstyles();
  const poststorystyles = postsuccessstorystyles();
  const [formData, setFormData] = useState({
    // brideName: "",
    // groomName: "",
    brideJmId: "",
    groomJmId: "",
    email: "",
    engagementDate: "",
    marriageDate: "",
    successStory: "",
    storyBy: "", // Corrected default value
    images: [],
  });
  const admin_data = useSelector((state) => state.authentication.data.adminId);
  const [imageURLs, setImageURLs] = useState([]);
  const [errors, setErrors] = useState({}); // State for error messages
  const [roleError, setRoleError] = useState(false);
  const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleinputchange = (event) => {
    const { name, value, type, checked } = event.target;
    // setLogindetails((prev) => ({
    //   ...prev,
    //   [name]: type === "checkbox" ? checked : value,
    // }));
  };
  const handleRemoveImage = (index) => {
    const updatedImages = formData.images.filter((_, i) => i !== index);
    const updatedImageURLs = imageURLs.filter((_, i) => i !== index);

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(imageURLs[index]);

    // Update the formData and imageURLs states
    setFormData({ ...formData, images: updatedImages });
    setImageURLs(updatedImageURLs);
  };

  const handleDateChange = (name, date) => {
    const isoDate = date.toISOString();
    setFormData({ ...formData, [name]: isoDate });
    setErrors({ ...errors, [name]: "" }); // Clear error message when date is selected
  };

  const handleFileChange = (files) => {
    const imagesArray = Array.from(files);

    // Filter out invalid file formats
    const validFiles = imagesArray.filter((file) =>
      allowedFormats.includes(file.type)
    );

    if (formData.images.length + validFiles.length > 5) {
      enqueueSnackbar("You can only upload up to 5 images.", {
        variant: "error",
      });
      return;
    }

    if (validFiles.length !== imagesArray.length) {
      enqueueSnackbar("Only JPG, JPEG, and PNG files are allowed.", {
        variant: "error",
      });
    }

    const newImages = [...formData.images];
    const newImageURLs = [...imageURLs];

    validFiles.forEach((file) => {
      const fileUrl = URL.createObjectURL(file);
      newImages.push(file);
      newImageURLs.push(fileUrl);
    });

    // Limit the total number of images to 5
    if (newImages.length > 5) {
      newImages.length = 5;
      newImageURLs.length = 5;
    }

    // Update state
    setFormData({ ...formData, images: newImages });
    setImageURLs(newImageURLs);
  };

  const validateForm = () => {
    const newErrors = {};
    const currentDate = new Date();
    // const yesterday = subDays(currentDate, 1);
    const engagementDate = new Date(formData.engagementDate);
    const marriageDate = new Date(formData.marriageDate);

    // if (!formData.brideName) newErrors.brideName = "Bride Name is required";
    // if (!formData.groomName) newErrors.groomName = "Groom Name is required";

    // Ensure JM prefix for brideJmId
    if (!formData.brideJmId || !formData.brideJmId.startsWith("JM00100")) {
      newErrors.brideJmId = "Please enter a valid Bride JM ID";
    }

    // Ensure JM prefix for groomJmId (if applicable)
    if (!formData.groomJmId || !formData.groomJmId.startsWith("JM00100")) {
      newErrors.groomJmId = "Please enter a valid Groom JM ID";
    }

    if (!formData.email) newErrors.email = "Email is required";

    if (!formData.engagementDate) {
      newErrors.engagementDate = "Engagement Date is required";
    } else if (engagementDate > currentDate) {
      newErrors.engagementDate = "Engagement Date cannot be in the future";
    }

    if (!formData.marriageDate) {
      newErrors.marriageDate = "Marriage Date is required";
    } else if (marriageDate > currentDate) {
      newErrors.marriageDate = "Marriage Date cannot be in the future";
    } else if (marriageDate < engagementDate) {
      newErrors.marriageDate = "Marriage Date cannot be before Engagement Date";
    }

    if (!formData.successStory)
      newErrors.successStory = "Success story is required";

    // Ensure at least one image is uploaded
    if (formData.images.length === 0) {
      newErrors.images = "At least one image is required";
    }

    return newErrors;
  };

  const handleFormSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      enqueueSnackbar(validationErrors[Object.keys(validationErrors)[0]], { variant: "error" });
      return;
    }

    try {
      const backendRequest = {
        adminId: admin_data,
        brideName: formData.brideName,
        groomName: formData.groomName,
        brideJmId: formData.brideJmId ? formData.brideJmId.replace(/^JM00100/, '') : null,
        groomJmId: formData.groomJmId ? formData.groomJmId.replace(/^JM00100/, '') : null,
        email: formData.email,
        engagementDate: formData.engagementDate,
        marriageDate: formData.marriageDate,
        successStory: formData.successStory,
        storyBy: formData.storyBy === "Bride" ? 1 : 2, // Corrected mapping for storyBy
      };

      const formDataWithImages = new FormData();
      if (formData.images.length > 0) {
        for (const file of formData.images) {
          formDataWithImages.append("images", file);
        }
      }
      for (const key in backendRequest) {
        if (backendRequest[key] !== null && backendRequest[key] !== undefined) {
          formDataWithImages.append(key, backendRequest[key]);
        }
      }

      const response = await postsuccessstory_api(formDataWithImages);
      console.log("API Response:", response);

      if (response?.message === "Successfully added success story") {
        enqueueSnackbar("Successfully added success story", { variant: "success" });
        setFormData({
          brideName: "",
          groomName: "",
          brideJmId: "",
          groomJmId: "",
          email: "",
          engagementDate: "",
          marriageDate: "",
          successStory: "",
          storyBy: "",
          images: [],
        });
        setImageURLs([]);
        close();
      } else {
        console.log("Failed:", response?.message);
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };


  return (
    <Grid item md={12}>
      <Paper elevation={5} className={poststorystyles.paper}>
        <Grid container direction={"column"} spacing={2}>
          <Grid
            item
            mt={-1}
            fontSize={"16px"}
            sx={{ color: color_exports.primary_color }}
          >
            Post New Success Story
          </Grid>
          <Grid item container mt={-0.5} spacing={2}>
            {/* <Grid item md={6}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Bride Name (Female)
                </Grid>
                <Grid item>
                  <Modifiedinputfield
                    fullWidth
                    placeholder="Enter Here"
                    name="brideName"
                    value={formData.brideName}
                    onChange={handleInputChange}
                    error={!!errors.brideName}
                    helperText={errors.brideName}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={6}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Groom Name (Male)
                </Grid>
                <Grid item>
                  <Modifiedinputfield
                    fullWidth
                    placeholder="Enter Here"
                    name="groomName"
                    value={formData.groomName}
                    onChange={handleInputChange}
                    error={!!errors.groomName}
                    helperText={errors.groomName}
                  />
                </Grid>
              </Stack>
            </Grid> */}

            <Grid item md={6}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Bride JM ID
                </Grid>
                <Grid item>
                  <Modifiedinputfield
                    fullWidth
                    placeholder="Enter Here (JM00100123)"
                    name="brideJmId"
                    value={formData.brideJmId}
                    onChange={handleInputChange}
                    error={!!errors.brideJmId}
                    helperText={errors.brideJmId}
                  />
                </Grid>
              </Stack>
            </Grid>

            <Grid item md={6}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Groom JM ID
                </Grid>
                <Grid item>
                  <Modifiedinputfield
                    fullWidth
                    placeholder="Enter Here (JM00100123)"
                    name="groomJmId"
                    value={formData.groomJmId}
                    onChange={handleInputChange}
                    error={!!errors.groomJmId}
                    helperText={errors.groomJmId}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={12}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Story By
                </Grid>
                <Grid item>
                  <FormControl fullWidth error={roleError}>
                    <Select
                      name="storyBy"
                      value={formData.storyBy}
                      onChange={handleInputChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>Select Role</MenuItem>
                      <MenuItem value="Bride">Bride</MenuItem>
                      <MenuItem value="Groom">Groom</MenuItem>
                    </Select>
                    <FormHelperText>{roleError ? "Role is required" : ""}</FormHelperText>
                  </FormControl>
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={12}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Email
                </Grid>
                <Grid item>
                  <Modifiedinputfield
                    fullWidth
                    placeholder="Enter Here"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={6}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Engagement Date
                </Grid>
                <Grid item>
                  <Modifieddate
                    placeholder={"dd/mm/yyyy"}
                    name="engagementDate"
                    value={formData.engagementDate}
                    onChange={(date) =>
                      handleDateChange("engagementDate", date)
                    }
                    error={!!errors.engagementDate}
                    helperText={errors.engagementDate}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={6}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Wedding Date
                </Grid>
                <Grid item>
                  <Modifieddate
                    placeholder={"dd/mm/yyyy"}
                    name="marriageDate"
                    value={formData.marriageDate}
                    onChange={(date) => handleDateChange("marriageDate", date)}
                    error={!!errors.marriageDate}
                    helperText={errors.marriageDate}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={12}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Success Story
                </Grid>
                <Grid item>
                  <Modifiedtextarea
                    fullWidth
                    minRows={4}
                    placeholder="Enter Here"
                    name="successStory"
                    value={formData.successStory}
                    onChange={handleInputChange}
                    error={!!errors.successStory}
                    helperText={errors.successStory}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item md={12}>
              <Stack spacing={1}>
                <Grid
                  item
                  fontSize={"14px"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Upload Photos
                </Grid>
                <Grid item>
                  <Modifiedbrowserfiles
                    placeholder={"Share Happy Moments"}
                    accept="image/*"
                    multiple
                    onChange={(e) => handleFileChange(e.target.files)}
                  />
                  {errors.images && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.images}
                    </div>
                  )}
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {imageURLs.map((url, index) => (
                      <div key={index} style={{ position: "relative" }}>
                        <img
                          src={url}
                          alt={`Selected ${index}`}
                          style={{
                            width: "100px",
                            height: "100px",
                            margin: "5px",
                          }}
                        />
                        <IconButton
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: 0,
                            color: "red",
                            backgroundColor: "white",
                          }}
                          size="small"
                          onClick={() => handleRemoveImage(index)}
                        >
                          <CancelIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Stack>
            </Grid>
            <Grid item container justifyContent={"center"} gap={2}>
              <Modifiedbutton
                data={"Cancel"}
                bgcolor={"#F2F2F2"}
                styles={{ color: "#9F9F9F" }}
                onClick={close}
              />
              <Modifiedbutton data={"Post"} onClick={handleFormSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PostStoryComp;
