import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Stack,
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
import { color_exports } from "../Styles/Color_exports";
import Modifiedbutton from "./Modifiedbutton";
import Modifiedinputfield from "./Modifiedinputfield";
import { subadminstyles } from "../Styles/Contentmanagement_styles";
import {
  updatesubadmin_api,
  getcountries_api,
  getstates_api,
  getcities_api,
} from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import { userdetail_styles } from "../Styles/Adminuser_details_styles";
import Vieweye from "./Vieweye";
import Hideeye from "./Hideeye";

const Editsubadmin = ({ subAdmin, onClose, onAddSubadmin }) => {
  const subadminstyle = subadminstyles();
  const details_style = userdetail_styles();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [editpwd, setEditpwd] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    cityId: "",
    stateId: "",
    countryId: "",
    privileges: {
      report: 0,
      messages: 0,
      successStories: 0,
      userManagement: 0,
      packageManagement: 0,
    },
    profilePic: null,
    deleteImageStatus: 0,
  });

  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [errors, setErrors] = useState({ phoneNumber: '' });
  
  useEffect(() => {
    if (subAdmin) {
      setFormData({
        name: subAdmin.name || "",
        phoneNumber: subAdmin.phoneNumber || "",
        email: subAdmin.email || "",
        password: "********" || "", // Default password
        cityId: subAdmin.cityId || "",
        stateId: subAdmin.stateId || "",
        countryId: subAdmin.countryId || "",
        privileges: {
          report: subAdmin.privileges?.report ? 1 : 0,
          messages: subAdmin.privileges?.messages ? 1 : 0,
          successStories: subAdmin.privileges?.successStories ? 1 : 0,
          userManagement: subAdmin.privileges?.userManagement ? 1 : 0,
          packageManagement: subAdmin.privileges?.packageManagement ? 1 : 0,
        },
        profilePic: subAdmin.profilePic
          ? `${process.env.REACT_APP_BACKEND_URL}/uploads/subAdminProfiles/${subAdmin.profilePic}`
          : null,
        deleteImageStatus: 0,
      });

      setCountryName(subAdmin.countryName || "");
      setStateName(subAdmin.stateName || "");
      setCityName(subAdmin.cityName || "");
    }
  }, [subAdmin]);

  
  const validatePhoneNumber = (value) => {
    // Check if the value contains only numbers
    return /^\d*$/.test(value);
};

const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "password") {
        setEditpwd(true);
    }

    // Validate phone number input
    if (name === "phoneNumber") {
        if (!validatePhoneNumber(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phoneNumber: "Please enter numbers only",
            }));
            return; // Do not update the formData if validation fails
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phoneNumber: "",
            }));
        }
    }

    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));

    if (name === "countryId") {
        try {
            const response = await getstates_api({
                countryId: value,
                min: 0,
                max: 1000,
                searchText: "",
            });

            if (response?.message === "states list") {
                setStates(response.data.rows);
                setCities([]);
                setFormData((prevData) => ({
                    ...prevData,
                    stateId: "",
                    cityId: "",
                }));
            } else {
                console.error(response?.message || "Failed to fetch states");
                enqueueSnackbar(response?.message || "Failed to fetch states", { variant: "error" });
            }
        } catch (err) {
            console.error("Error:", err);
            enqueueSnackbar(err.message || "Failed to fetch states", { variant: "error" });
        }
    }

    if (name === "stateId") {
        try {
            const response = await getcities_api({
                stateId: value,
                min: 0,
                max: 1000,
                searchText: "",
            });

            if (response?.message === "cities list") {
                setCities(response.data.rows);
                setFormData((prevData) => ({
                    ...prevData,
                    cityId: "",
                }));
            } else {
                console.error(response?.message || "Failed to fetch cities");
                enqueueSnackbar(response?.message || "Failed to fetch cities", { variant: "error" });
            }
        } catch (err) {
            console.error("Error:", err);
            enqueueSnackbar(err.message || "Failed to fetch cities", { variant: "error" });
        }
    }
};

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        profilePic: file,
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      privileges: {
        ...prevState.privileges,
        [name]: checked ? 1 : 0,
      },
    }));
  };
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getcountries_api();
        if (response && response.message === "countries list") {
          setCountries(response.data.rows);
          // enqueueSnackbar(response.message, { variant: "success" });
        } else {
          console.error(response?.message || "Failed to fetch countries");
          enqueueSnackbar(response?.message || "Failed to fetch countries", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message || "Failed to fetch countries", {
          variant: "error",
        });
      }
    };

    const fetchStates = async () => {
      try {
        const response = await getstates_api({
          countryId: subAdmin.countryId,
          min: 0,
          max: 1000,
          searchText: "", // Optional: can be used for filtering
        });
        if (response && response.message === "states list") {
          setStates(response.data.rows);
        } else {
          console.error(response?.message || "Failed to fetch states");
          enqueueSnackbar(response?.message || "Failed to fetch states", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message || "Failed to fetch states", {
          variant: "error",
        });
      }
    };

    const fetchCities = async () => {
      try {
        const response = await getcities_api({
          stateId: subAdmin.stateId,
          min: 0,
          max: 1000,
          searchText: "", // Optional: can be used for filtering
        });
        if (response && response.message === "cities list") {
          setCities(response.data.rows);
        } else {
          console.error(response?.message || "Failed to fetch cities");
          enqueueSnackbar(response?.message || "Failed to fetch cities", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message || "Failed to fetch cities", {
          variant: "error",
        });
      }
    };

    fetchCountries();
    fetchStates();
    fetchCities();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("subAdminId", subAdmin.adminId);
      formDataToSend.append("name", formData.name);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("cityId", formData.cityId);
      formDataToSend.append("stateId", formData.stateId);
      formDataToSend.append("countryId", formData.countryId);

      const privileges = {
        report: Number(formData.privileges.report),
        messages: Number(formData.privileges.messages),
        successStories: Number(formData.privileges.successStories),
        userManagement: Number(formData.privileges.userManagement),
        packageManagement: Number(formData.privileges.packageManagement),
      };

      formDataToSend.append("privileges", JSON.stringify(privileges));

      if (formData.profilePic) {
        formDataToSend.append("subAdminProfileImage", formData.profilePic);
      }

      formDataToSend.append("deleteImageStatus", formData.deleteImageStatus);
      if (editpwd) {
        formDataToSend.append("password", formData.password);
      }
      const response = await updatesubadmin_api(formDataToSend);

      if (response?.message === "Sub Admin details updated") {
        enqueueSnackbar("Sub Admin details updated", { variant: "success" });
        onAddSubadmin();
        setEditpwd(false);
        onClose();
      } else {
        enqueueSnackbar(response?.message || "Failed to update sub-admin", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message || "Failed to update sub-admin", {
        variant: "error",
      });
    }
  };

  console.log(subAdmin.cityId, "subadmin");
  return (
    <div>
      <Paper elevation={5} sx={{ padding: "1rem", borderRadius: "1rem" }}>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            <Grid item className={details_style.mainheading}>
              {" "}
              Edit Sub Admin{" "}
            </Grid>
            <Grid item container>
              <Grid
                item
                md={4}
                mt={2}
                className={subadminstyle.cardviewimagecontainer}
                container
              >
                <Grid item mt={4}>
                  <label htmlFor="file-upload">
                    <img
                      // src={formData.profilePic}
                      src={formData.profilePic? typeof formData.profilePic ===
                        "object"
                        ? URL.createObjectURL(
                          formData.profilePic
                          )
                        : formData.profilePic:""}
                      alt="Profile"
                      className={subadminstyle.uploadimg}
                    />
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Grid>
              </Grid>

              <Grid item md={8}>
                <Stack spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item md={11.5}>
                      <Stack spacing={1}>
                        <Grid item sx={{ color: color_exports.primary_color }}>
                          {" "}
                          Name{" "}
                        </Grid>
                        <Grid item>
                          <Modifiedinputfield
                            fullWidth
                            placeholder="Enter Here"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid item md={11.5} mt={2}>
                    <Stack spacing={1}>
    <Grid item sx={{ color: color_exports.primary_color }}>
        Phone Number
    </Grid>
    <Grid item>
        <Modifiedinputfield
            fullWidth
            placeholder="Enter Here"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            error={!!errors.phoneNumber} // Pass error status
            helperText={errors.phoneNumber} // Pass error message
        />
    </Grid>
</Stack>

                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>

            <Grid item md={12} mt={4}>
              <Stack spacing={1}>
                <Grid item sx={{ color: color_exports.primary_color }}>
                  {" "}
                  Email{" "}
                </Grid>
                <Grid item>
                  <Modifiedinputfield
                    placeholder="Enter here"
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Grid>
              </Stack>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item md={6}>
                <Stack spacing={1}>
                  <Grid item sx={{ color: color_exports.primary_color }}>
                    Password
                  </Grid>
                  <Grid item>
                    <Modifiedinputfield
                      size={"medium"}
                      placeholder="Enter here"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      autoComplete="off"
                      type={passwordVisible ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            style={{ cursor: "pointer" }}
                          >
                            {editpwd ? (
                              passwordVisible ? (
                                <Vieweye />
                              ) : (
                                <Hideeye />
                              )
                            ) : null}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Stack>
              </Grid>
              <Grid item md={6} sx={{ marginBottom: "0.5rem" }}>
                <Stack spacing={1}>
                  <Grid item sx={{ color: color_exports.primary_color }}>
                    Country
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <Select
                        name="countryId"
                        value={formData.countryId}
                        onChange={handleChange}
                        displayEmpty
                        className={subadminstyle.selectbg}
                        sx={{
                          "&:before": {
                            borderBottom: "none", // Remove border bottom
                          },
                          "&:after": {
                            borderBottom: "none", // Remove border bottom
                          },
                          "&:hover:not(.Mui-disabled):before": {
                            borderBottom: "none", // Remove border bottom on hover
                          },
                          "& .MuiInputBase-root": {
                            fontSize: "1rem", // Adjust font size if needed
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          <span className={subadminstyle.selectplaceholder}>
                            Country
                          </span>
                        </MenuItem>
                        {countries.map((country) => (
                          <MenuItem
                            key={country.countryId}
                            value={country.countryId}
                          >
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item md={6} sx={{ marginBottom: "0.5rem" }}>
                <Stack spacing={1}>
                  <Grid item sx={{ color: color_exports.primary_color }}>
                    State
                  </Grid>
                  <FormControl fullWidth>
                    <Select
                      name="stateId"
                      value={formData.stateId}
                      onChange={handleChange}
                      displayEmpty
                      className={subadminstyle.selectbg}
                      sx={{
                        "&:before": {
                          borderBottom: "none", // Remove border bottom
                        },
                        "&:after": {
                          borderBottom: "none", // Remove border bottom
                        },
                        "&:hover:not(.Mui-disabled):before": {
                          borderBottom: "none", // Remove border bottom on hover
                        },
                        "& .MuiInputBase-root": {
                          fontSize: "1rem", // Adjust font size if needed
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <span className={subadminstyle.selectplaceholder}>
                          State
                        </span>
                      </MenuItem>
                      {states.map((state) => (
                        <MenuItem key={state.stateId} value={state.stateId}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  
                </Stack>
              </Grid>

              <Grid item md={6} sx={{ marginBottom: "0.5rem" }}>
                <Stack spacing={1}>
                  <Grid item sx={{ color: color_exports.primary_color }}>
                    City
                  </Grid>
                  <FormControl fullWidth>
                    <Select
                      name="cityId"
                      value={formData.cityId}
                      onChange={handleChange}
                      displayEmpty
                      className={subadminstyle.selectbg}
                      sx={{
                        "&:before": {
                          borderBottom: "none", // Remove border bottom
                        },
                        "&:after": {
                          borderBottom: "none", // Remove border bottom
                        },
                        "&:hover:not(.Mui-disabled):before": {
                          borderBottom: "none", // Remove border bottom on hover
                        },
                        "& .MuiInputBase-root": {
                          fontSize: "1rem", // Adjust font size if needed
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <span className={subadminstyle.selectplaceholder}>
                          City
                        </span>
                      </MenuItem>
                      {cities.map((city) => (
                        <MenuItem key={city.cityId} value={city.cityId}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Grid>

        

          <Grid item container mt={2}>
            <Stack spacing={1}>
              <Grid item sx={{ color: color_exports.primary_color }}>
                {" "}
                Privileges{" "}
              </Grid>
              <Grid container spacing={2} fontSize="12px">
                  {[
                    { label: "User Management", name: "userManagement" },
                    { label: "Packages Management", name: "packageManagement" },
                    { label: "Report", name: "report" },
                    { label: "Success Stories", name: "successStories" },
                    { label: "Messages", name: "messages" },
                  ].map((privilege, index) => (
                    <Grid item key={index}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px",
                        }}
                      >
                        <Checkbox
                          name={privilege.name}
                          checked={formData.privileges[privilege.name]}
                          onChange={handleCheckboxChange}
                          style={{
                            color: "#b8003e",
                            width: "17px",
                            height: "17px",
                          }}
                          disableRipple
                        />
                        <span
                          onClick={(e) => e.stopPropagation()} // Prevent clicks on the label from affecting the checkbox
                          style={{
                            marginLeft: "8px",
                            cursor: "default",
                            fontSize: "16px",
                          }} // Default cursor for the label
                        >
                          {privilege.label}
                        </span>
                      </div>
                    </Grid>
                  ))}
                </Grid>
            </Stack>
          </Grid>

          <Grid item container px={3} justifyContent={"end"} mt={2} mb={2}>
            <Modifiedbutton
              data={"Cancel"}
              bgcolor={"#F2F2F2"}
              styles={{ color: "#9F9F9F" }}
              onClick={onClose}
            />
            <Modifiedbutton
              data={"Update"}
              swidth={"5rem"}
              className={subadminstyle.addbtn}
              type="submit"
            />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default Editsubadmin;
