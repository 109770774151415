import { Route, Routes } from 'react-router-dom';
import Admin_login from '../pages/Admin_login';
import Adminlayout from '../Layouts/Adminlayout';
import Admindashboard from '../pages/Admindashboard';
import AdminUsermanagement from '../pages/Usermanagement/AdminUsermanagement';
import User_details from '../pages/Usermanagement/User_details';
import Package_management from '../pages/Package_management';
import Success_stories from '../pages/Success_stories';
import Content_management from '../pages/Content_management';
import Settings from '../pages/Settings';
import Sub_admin_management from '../pages/Sub_admin_management';
import Notifications from '../pages/Notifications';
import Reports from '../pages/Reports';
import Analytics from '../pages/Analytics';
import Messages from '../pages/Messages';
import Otp from '../Models/Otp';
import PublicRoute from './Publicroutes';
import PrivateRoute from './Privateroutings';
import Deleteuser from '../pages/Deleteuser';

const Loginroutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicRoute component={Admin_login} restricted />} />
      <Route path="deleteuser" element={<Deleteuser />} />
      <Route path="/admin/*" element={<PrivateRoute component={Adminlayout} />}>
        <Route index element={<Admindashboard />} />
        <Route path="usermanagement" element={<AdminUsermanagement />} />
        <Route path="userdetails" element={<User_details />} />
        <Route path="packagemanagement" element={<Package_management />} />
        <Route path="success" element={<Success_stories />} />
        <Route path="contentmanagement" element={<Content_management />} />
        <Route path="settings" element={<Settings />} />
        <Route path="sub_adminmanagement" element={<Sub_admin_management />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="reports" element={<Reports />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="messages" element={<Messages />} />
        <Route path="otp" element={<Otp />} />
    
      </Route>
    </Routes>
  );
};

export default Loginroutes;
