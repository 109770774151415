import React from 'react'
import { TextField } from "@mui/material";
import { color_exports } from '../Styles/Color_exports';
import { custominputstyles } from '../Styles/Customcomponentstyles';

const Inputfield = (props) => {
    const { styles, onChange, ...otherprops } = props;
    const commoninputstyle = custominputstyles();
    const handleinputchange = (event) => {
       let newValue = event.target.value ?? "";
       
       if (props.onChange) {
         props.onChange({
         ...event,
         target: { ...event.target, value: newValue, name: props?.name ?? "" },
    });
    }
  };

  return (
    <>
      <TextField
        className={commoninputstyle.logininputstyle}
        sx={{
          // background:`${style_exports.grey_color}!important`,
          "& .MuiOutlinedInput-root": {
            background: `${
              props.bg_color === undefined || !props.bg_color
                ? color_exports.input_color
                : props.bg_color
            }!important`,
            borderRadius: `0.5rem`,
            // height: `3.5rem`,
            "& fieldset": {
              border: "none", // Remove the border
            },
          },
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            "-webkit-box-shadow": `0 0 0 30px ${
              props.bg_color === undefined || !props.bg_color
                ? color_exports.input_color
                : props.bg_color
            } inset !important`,
            background: "transparent !important",
          },
          // borderRadius:`${input_radius}`,
          border: "none!important",
          ...styles,
        }}

        size="medium"
        onChange={handleinputchange} //handleinputchange
        {...otherprops}
      />
    </>
  );
};
export default Inputfield
