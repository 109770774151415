import React from "react";
import { Chip, Grid } from "@mui/material";
import Verify from "../assets/Icons/Verify";
import { chipiconstyle } from "../Styles/Customcomponentstyles";
import { formatDistanceToNow } from "date-fns";
import user1 from "../assets/Images/user1.png";

const Cardinfo = ({ report, imageBaseUrl }) => {
  const chipicon = chipiconstyle();

  const timeAgo = formatDistanceToNow(new Date(report.createdAt), {
    addSuffix: true,
  });

  return (
    <Grid container spacing={2} sx={{ position: "relative" }}>
      <Grid item md={2}>
        <img
          src={
            report.profileImages[0]?.image1
              ? `${imageBaseUrl}${report.profileImages[0].image1}`
              : user1
          }
          alt="profile"
          style={{ width: 50, height: 50, borderRadius: "50%", marginLeft: '-0.5rem', objectFit: 'cover' }}
        />
      </Grid>
      <Grid item md={7} container direction="column" justifyContent="center">
        <Grid container alignItems="center" spacing={1}>
          <Grid item fontSize={"12px"} fontWeight={700}>
            {report.firstName}
          </Grid>
          <Grid item>
            {/* <Verify /> */}
          </Grid>
        </Grid>
        <Grid item fontSize={"12px"} sx={{ color: "#9D9D9D" }}>
          {report.message}
        </Grid>
      </Grid>
      <Grid item md={3} container direction="column" mt={1}>
        <Grid item fontSize={"12px"} sx={{ color: "#9D9D9D" }}>
          {timeAgo}
        </Grid>
        {/* <Grid item>
          <Chip label={report.chip_label} size="small" className={chipicon.chipStyles} />
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Cardinfo;
