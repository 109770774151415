import { PieChart, Pie, Cell, Label } from 'recharts';
import React from 'react';
import { Box, Typography } from '@mui/material';

const Modifiedpiechart = ({
  pieHeight = 220,
  outerData = [],
  
}) => {
  const formattedData =
    outerData?.map((item) => ({
      label: item.label,
      value: parseInt(item.value, 10),
    })) || [];
    const filteredData1 = formattedData.filter(item => 
      item.label === 'Recent Registrations' || item.label === 'Guest Users'
    );
  
    const totalCount = filteredData1.reduce((acc, item) => acc + item.value, 0);

  // const totalCount = formattedData.reduce((acc, item) => acc + item.value, 0);

  // Define an array of colors for each segment
  const COLORS = ['#5CBFF6', '#EF82F9', '#66EC62', '#6F5AF4'];

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {formattedData.length === 0 || totalCount === 0 ? (
        <Typography variant="h6" color="textSecondary" mt={2}>
          No data found
        </Typography>
      ) : (
        <>
          <PieChart width={200} height={pieHeight}>
            <Pie
              data={formattedData}
              innerRadius={45}
              outerRadius={80}
              fill="#8884d8"
            >
              {formattedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
              <Label
                value={`Total: ${totalCount}`}
                position="center"
                fontSize={20}
                fill="#333"
              />
            </Pie>
          </PieChart>

          {/* Display user counts below the PieChart */}
          <Box mt={2}>
            {formattedData.map((item, index) => (
              <Typography key={index} variant="body1" gutterBottom>
                <Box display="flex">
                  <Box
                    width={15}
                    height={15}
                    borderRadius="50%"
                    bgcolor={COLORS[index % COLORS.length]}
                    // mr={1}
                    ml={1}
                  />
                  <Typography variant="body1" style={{marginLeft: "5px", fontSize:"12px"}}>{`${item.label}: ${item.value}`}</Typography>
                </Box>
              </Typography>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Modifiedpiechart;
