import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import axios from "axios";
import { modifiedtable_styles } from "../Styles/Modifiedstyles";
import { userdetail_styles } from "../Styles/Adminuser_details_styles";
import blockicon from "../assets/Images/blockicon.png";
import { StorageClient } from "../Helpers/Helperfunctions";

const Guestusertable = (props) => {
  const modifiedstyle = modifiedtable_styles();
  const details_style = userdetail_styles();
  const {
    maxheight,
    rows,
    columns,
    pageNumber,
    pageSize,
    refreshData, // function to refresh table data after API call
  } = props;

  const [usersStatus, setUsersStatus] = useState({});
  const [openDialog, setOpenDialog] = useState(false); // For controlling the confirmation dialog
  const [selectedUserId, setSelectedUserId] = useState(null); // Store the userId of the user being blocked/unblocked
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedType, setSelectedType] = useState(null);// Store the action (block/unblock)
  const [loading, setLoading] = useState(false); // For controlling loading state while making the API request

  // Function to handle block/unblock API call
 // Open confirmation dialog for block/unblock
const handleOpenDialog = (userId, action,type) => {
  setSelectedUserId(userId);
  setSelectedAction(action);
  setSelectedType(type);
  setOpenDialog(true); // Open the dialog to confirm action
};

// Function to handle block/unblock API call
const handleBlockStatusChange = async (userId, currentStatus, type) => {
  const token = StorageClient.getItem("token");
  try {
    setLoading(true); // Start loading
    const updatedStatus = currentStatus === 1 ? 2 : 1; // 1 -> block, 2 -> unblock
    
    // Close the dialog immediately after initiating the action
    handleCloseDialog();

    // Proceed with the API request after closing the dialog
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/updateBlockStatus`,
      {
        userIds: [userId], // The actual user IDs being passed as an array
        status: updatedStatus,
        type: type, // Adjust according to your logic for user types
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    if (
      response.data.message === "Blocked User Data Successfully" ||
      response.data.message === "Unblocked User Data Successfully"
    ) {
      // Update the user's block status in the local state
      setUsersStatus((prevStatus) => ({
        ...prevStatus,
        [userId]: updatedStatus, // Update the status for this user
      }));
      refreshData(); // Refresh the table data after successful block/unblock
    } else {
      console.error("Error updating block status:", response.data.message);
    }
  } catch (error) {
    console.error("API call failed:", error);
  } finally {
    setLoading(false); // Stop loading
  }
};

// Close the confirmation dialog
const handleCloseDialog = () => {
  setOpenDialog(false);
  setSelectedUserId(null);
  setSelectedAction(null);
};


  // Close the confirmation dialog
  

  return (
    <div>
      <TableContainer
        className={modifiedstyle.tablecontainer1}
        sx={{ maxHeight: maxheight ?? 440 }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => (
                <TableCell
                  key={column?.id || index}
                  align={column?.align || "left"}
                  className={modifiedstyle.stickyHeader2}
                  sx={{ minWidth: column?.minWidth }}
                >
                  <div className={modifiedstyle.stickyHeader3}>
                    <span>{column?.label}</span>
                  </div>
                </TableCell>
              ))}
              <TableCell
                className={details_style.cellsty}
                style={{
                  borderRight: "1px solid rgba(51, 51, 51, 0.2)",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => {
              const rowindex = index + 1;
              const backgroundColor =
                rowindex % 2 === 0 ? "#efeeeefa" : "#FFFFFF";

              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row?.userId || index}
                  sx={{ backgroundColor }}
                >
                  {columns?.map((column) => {
                    const value =
                      column?.label === "S.NO"
                        ? (pageNumber - 1) * pageSize + rowindex
                        : row?.[column?.label] ?? "";

                    return (
                      <TableCell
                        key={column?.id || index}
                        align={column?.align || "left"}
                        className={modifiedstyle.tablecell1}
                      >
                        {value}
                      </TableCell>
                    );
                  })}

                  {/* Action column */}
                  <TableCell
                    style={{
                      borderRight: "1px solid rgba(51, 51, 51, 0.2)",
                      textAlign: "center",
                    }}
                  >
                    {usersStatus?.[row?.userId] === 1 || row?.blockStatus ? (
                      // Show unblock icon (red) when the user is blocked
                      <RemoveCircleOutlineIcon
                        style={{
                          cursor: "pointer",
                          paddingLeft: "8px",
                          width: "18px",
                          color: "red", // Red color for unblock icon
                        }}
                        onClick={() => handleOpenDialog(row?.userId, "unblock", row?.type)}
                      />
                    ) : (
                      // Show block icon when the user is not blocked
                      <img
                        src={blockicon}
                        alt="Block"
                        style={{ cursor: "pointer", paddingLeft: "8px" }}
                        onClick={() => handleOpenDialog(row?.userId, "block", row?.type)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ textAlign: "center" , fontSize: "16px"}}>
          {selectedAction === "block"
            ? "Are you sure you want to block this user?"
            : "Are you sure you want to unblock this user?"}
        </DialogTitle>
       
        <DialogActions>
          <Button onClick={handleCloseDialog} style={{ fontSize: "14px", color: "#b8003e", fontWeight: "700", fontFamily: "Open Sans"}}>
            No
          </Button>
          <Button
            onClick={() => {
              handleBlockStatusChange(
                selectedUserId,
                selectedAction === "block" ? 2 : 1,
                selectedType 
              );
            }}
            style={{ fontSize: "14px", color: "#b8003e", fontWeight: "700", fontFamily: "Open Sans"}}
            disabled={loading} // Disable the button while loading
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" /> // Show loading spinner
            ) : (
              selectedAction === "block" ? "Block" : "Unblock"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Guestusertable;
