import { Avatar } from "@mui/material";
export const castingcall_columns = [
  {
    id: "S.NO",
    label: "S.NO",
    minWidth: 10,
  },
  {
    id: "ID",
    label: "ID",
    minWidth: 150,
    // type: "sorting"
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 200,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "Age",
    label: "Age",
    minWidth: 50,
    // align: "right",
    type: "sorting",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "Createdon",
    label: "Created On",
    minWidth: 110,
    type: "sorting",
    format: (value) => value.toLocaleString("en-IN"),
  },
  // {
  //   id: "Joined on",
  //   label: "Joined on",
  //   minWidth: 100,
  //   // align: "right",
  //   type: "sorting",
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: "Gender",
    label: "Gender",
    minWidth: 100,
    // align: "right",
    type: "sorting",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Religion",
    label: "Religion",
    minWidth: 100,
    // align: "right",
    type: "sorting",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Location",
    label: "Location",
    minWidth: 150,
    // align: "right",
    type: "sorting",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Package",
    label: "Package",
    minWidth: 130,
    // align: "right",
    type: "sorting",
    format: (value) => value.toFixed(2),
  },
  {
    id: "PackageStatus",
    label: "Package Status",
    minWidth: 120,
    type: "sorting",
    format: (value) => value.toLocaleString("en-IN"),
  },
  // {
  //   id: "Actions",
  //   label: "Actions",
  //   minWidth: 100,
  //   // align: "right",
  //   format: (value) => value.toFixed(2),
  //   actions: [0, 1, 2],
  // },
];

export const regesteredcolumns = [
  {
    id: "S.NO",
    label: "S.NO",
    minWidth: 10,
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 180,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "Email",
    label: "Email",
    minWidth: 180,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "Phone Number",
    label: "PhoneNumber",
    minWidth: 180,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "Religion",
    label: "Religion",
    minWidth: 180,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "Looking For",
    label: "LookingFor",
    minWidth: 180,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
  // {
  //   id: "Action",
  //   label: "Action",
  //   minWidth: 180,
  //   // align: "right",
  //   format: (value) => value.toLocaleString("en-IN"),
  // }
];

export const guestusers_columns = [
  {
    id: "S.NO",
    label: "S.NO",
    minWidth: 10,
  },

  {
    id: "Name",
    label: "Name",
    minWidth: 180,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
 
  {
    id: "Email",
    label: "Email",
    minWidth: 180,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "Phone Number",
    label: "PhoneNumber",
    minWidth: 180,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "Religion",
    label: "Religion",
    minWidth: 180,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
  {
    id: "Looking For",
    label: "LookingFor",
    minWidth: 180,
    // align: "right",
    format: (value) => value.toLocaleString("en-IN"),
  },
  
];
