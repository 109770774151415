import React, { useEffect, useState } from "react";
import { color_exports } from "../Styles/Color_exports";
import { Grid } from "@mui/material";
import userinfoimg from "../assets/Images/userinfoimg.png";
import { getusers_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import { userdetail_styles } from "../Styles/Adminuser_details_styles";

const Newuserinfo = () => {
  const [usersInfo, setUsersInfo] = useState([]);
  const [imageBaseUrl, setImageBaseUrl] = useState("");
  const details_style = userdetail_styles();
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const req = {
          min: 0,
          max: 5,
          // sortValue: "age",
          // sortBy: "DESC",
          type: 2,
        };

        const retrievesubadminres = await getusers_api(req);

        if (retrievesubadminres?.message === "Successfully retrived Users data") {
          // enqueueSnackbar("Successfully retrieved Users data", { variant: "success" });
          setUsersInfo(retrievesubadminres.data.rows);
          setImageBaseUrl(retrievesubadminres.data.imageBaseUrl);
        } else {
          console.log("Failed:", retrievesubadminres?.message);
          enqueueSnackbar(retrievesubadminres?.message, { variant: "error" });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message, { variant: "error" });
      }
    };
    fetchUserInfo();
  }, []);

  if (usersInfo.length === 0) {
    return <div>No Data Available</div>;
  }

  return (
    <Grid container direction={"column"} spacing={1}>
      {usersInfo.map((user) => {
        const userImage = user.profileImages.length > 0 
          ? `${imageBaseUrl}${user.profileImages[0].image1}`
          : userinfoimg;

        const fullName = `${user.firstName} ${user.lastName}`;
        const age = new Date().getFullYear() - new Date(user.dateOfBirth).getFullYear();
        const gender = user.gender === "FEMALE" ? "F" : "M";

        return (
          <Grid key={user.userId} item container spacing={0.5}>
            <Grid item md={"auto"}>
              <img src={userImage} alt="User Profile" className={details_style.accimg}/>
            </Grid>
            <Grid item md container direction={"column"} alignSelf={"center"}>
              <Grid item container justifyContent={"space-between"} spacing={1}>
                <Grid item md={"auto"} fontSize={"12px"}>
                  {fullName}, {age}, {gender}
                </Grid>
              </Grid>
              <Grid item fontSize={"12px"} sx={{ color: color_exports.icon_color }}>
                {user.permanentCity}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Newuserinfo;
